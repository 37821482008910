import React, { useState } from "react";
import InfoSection from "../components/infoSection";
import { NavbarBusiness } from "../components/navbar/indexBusiness";
import { SidebarB } from "../components/sidebar/indexB";
import Image9 from "../images/aihome3.webp";
import Footer from "../components/footer";


const Busi5 = () => {
    const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
    <SidebarB isOpen={isOpen} toggle={toggle} />
    <NavbarBusiness toggle={toggle} />
  

<InfoSection
      image={Image9}
      id="/home"
      subtitle=""
      title="AI Enabled Smart Home Solutions"
      text=" Smart Homes use AI and IOT technologies such as connected sensors, lighting, and meters to collect and analyze data to make more efficient. NTC Digital has expertise in following solutions:" 
      textA="◓ Smart Electricals"
      text1B="◓ Smart Adaptive Lighting"
      text2C="◓ Smart Home Security"
      text3D="◓ Smart CCTV"
      text1="◓ Smart Environment Control"
      text2="◓ Smart Windows"
      text3="◓ Smart Water & Leakage Detections"
      text4="◓ Smart Audio & Visuals"
                  

      // btnText="Start today"
    />
    <Footer />
  </>
);
};

export default Busi5;