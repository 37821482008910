import React from "react";
import "./job.scss";


const Job = (props) => {
  const {
    company,
    contract,
    featured,
    // id,
    // languages,
    // level,
    location,
    // logo,

    position,
    postedAt,
    // role,
    // tools,
  } = props.data;

  // let keywords = [role, level, ...languages, ...tools];

  // const [icon, setIcon] = useState("");

  // const importSvgs = () => {
  //   const logoSvg = import(`${logo}`).then((d) => {
  //     setIcon(d.default);
  //   });
  // };

  // useEffect(() => {
  //   importSvgs();
  // }, [logo]);

  return (
    <div
      className={
        featured ? "job-container job-container--borderLeft" : "job-container"
      }
    >
      {/* <div className="logo">
        <img src={icon} alt="" />
      </div> */}
      <div className="part1">
        <div className="company">
          <span className="cname">{company}</span>
          {props.data.new && <span className="new">new!</span>}
          {props.data.featured && <span className="featured">featured</span>}
        </div>

        <div className="position">{position}</div>

        <div className="details">
          <span>{postedAt}</span>
          {/* <span>&nbsp;•&nbsp;</span> */}
          <span>{contract}</span>
          <span>&nbsp;•&nbsp;</span>
          <span>{location}</span>
        </div>
      </div>

      <div className="part2">
        
          <span>
          <a style={{ textDecoration: 'none'}} href="mailto:Info@ntcdigital.net?Subject=Job%20Application" target="_top">Apply now</a>
          </span>
        
      </div>
    </div>
  );
};

export default Job;
