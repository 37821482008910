import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import { InfoContainer, InfoWrapper,  Title } from "./infoElements";
// import { ButtonBasic } from "../ButtonElements";

export const InfoSection2 = ({ id, TitleInfo, textInfo, TitleInfo1, textInfo1,image}) => {
  return (
    <>
      <InfoContainer id={id}>
        <InfoWrapper>
          <div className="row expand-row gx-5">
            <div className="col-lg-6 col-sm-12 my-auto"> 
                <Title className="mb-3">{TitleInfo}</Title>
                <p className="fst-italic shadow p-3 mb-3  rounded para">{textInfo}</p>
                <Title className="mb-3">{TitleInfo1}</Title>
                <p className="fst-italic shadow p-3 mb-4  rounded para">{textInfo1}</p>      
            </div>
            <div className="col-lg-6 col-sm-12 img-wrap" >
              <img src={image} alt="" className=" fit-img shadow p-0 mt-3 bg-body rounded" ></img> 
            </div>
          </div>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection2;
