import styled from "styled-components";

export const InfoContainer = styled.div `
    width: 100%;
    min-height: 700px;    
    justify-content: center;  
    display: flex;

`

export const InfoWrapper = styled.div `
    display: flex;
    padding: 80px 0;
    width: 1200px;
    justify-content: center;
    align-items: center;
    .imgh{
        // height: 400px;
        // width: 630px;
        margin-top: 65px;
        padding:20px;
        // border: 20px solid #5e490e;
      };
    .para{
        text-align: justify;
        
    };

`

export const Title = styled.h1 `
    font-weight: 900;

`

export const Subtitle = styled.h5 `
    color: #B05FFD;
    font-weight: 500;
    text-align: right;
    text-transform: uppercase;

`
export const imgg = styled.div `
    display: flex;
    padding: 80px 100;
    margin: 80px 100;
    justify-content: center;
    align-items: center;

`
