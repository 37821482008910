import React from 'react'
import {SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SidebarLinkR, SideBtnWrap, A} from './SidebarElements'

export const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="about" onClick={toggle}>
                        About
                    </SidebarLink>
                    <SidebarLink to="discover" onClick={toggle}>
                        Partners
                    </SidebarLink>
                    <SidebarLink to="services" onClick={toggle}>
                        Services
                    </SidebarLink>
                    <SidebarLink to="Contact" onClick={toggle}>
                        Contact
                    </SidebarLink>
                    <SidebarLinkR to="/busi" onClick={toggle}>
                        Bussiness Solutions
                    </SidebarLinkR>
                    <SidebarLinkR to="/infra" onClick={toggle}>
                        Infrastructure Solutions
                    </SidebarLinkR>
                </SidebarMenu>
                <SideBtnWrap>
                    <A href="http://ntcdigitalapp.com/Pages/Login.aspx">Login</A>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}