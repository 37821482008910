import React, { useState } from "react";
import InfoSection from "../components/infoSection";
import { NavbarInfra } from "../components/navbar/indexInfra";
import { SidebarI } from "../components/sidebar/indexI";


import Image2 from "../images/SmartCity.jpg";
import Footer from "../components/footer";



const Infra2 = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);

  };

  return (
    <>
    <SidebarI isOpen={isOpen} toggle={toggle} />
    <NavbarInfra toggle={toggle} />
  
    <InfoSection
      image={Image2}
    //   id="about"
      subtitle=""
      title="Physical Security and Smart City"
      text="NTC Digital is one of the pioneering companies of Saudi that specializes in planning and implementing smart
            city solutions. As urban population cities to swell due to high demand in migration on account of better job
            opportunities, efficient delivery of services to citizens becomes paramount.
            The need for access to quality services and smarter communities is becoming increasingly important every
            passing day.
            By combining our experience in Internet of Things (IoT) with GIS, big data and integrated security
            management technologies, NTC Digital is providing rich experiences to urbanites. We have worked on variety
            of projects with  governments, leading telecom operators, banking institutions and electricity supply
            companies to realize the ambition of smarter cities." 
      // text1="IP Telephony"
      // text2="Unified Communications"
      // text3="Conferencing - Audio, Video & Web"
      // text4="Contact Centers"
                  

      // btnText="Start today"
    />

  

    <Footer />
  </>
);
};

export default Infra2;