import React, { useState } from "react";
// import Video3 from "./../videos/video.mp4";
// import HeroSection from "../components/heroSection";
// import InfoSection from "../components/infoSection";
import { NavbarInfra } from "../components/navbar/indexInfra";
import { SidebarI } from "../components/sidebar/indexI";

import Image1 from "../images/manage.png";
// import Image2 from "../images/city.svg";
// import Image3 from "../images/message.svg";
// import Image4 from "../images/network.svg";
// import Image5 from "../images/partners1.jpg";
// import Image6 from "../images/partners2.jpg";
// import Image7 from "../images/busilogo.gif";
import InfoSectionLight from "../components/infoSectionLight";
import Footer from "../components/footer";


const ServicePage3 = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);

  };

  return (
    <>
    <SidebarI isOpen={isOpen} toggle={toggle} />
    <NavbarInfra toggle={toggle} />
    {/* <HeroSection videoId={Video3}
      textH="Infrastructure Solutions"
      textP="With our expertise  in Structured Cabling, Enterprise Network
             and Physical Security for Smart City, We provides you the best solutions"
    /> */}
    <InfoSectionLight
        image={Image1}
        // id="Contact"
        // subtitle="Duis et lectus accumsan"
        title="Managed Services"
        // text="Managed services is the practice of transferring day-to-day related management responsibility as a strategic method for improved effective and efficient operations inclusive of Production Support and lifecycle build/maintenance activities."
        textA="NTC Digital offers managed services to its customers in the following areas:"
        // textB="1. IT Helpdesk Services"
        // textC="2. Datacenter Services"
        textAA="◓ IT Helpdesk Services"
        textBB="◓ Datacenter Services"
        textCC="◓ Managed Network Services"
        textAA1="◓ Managed Voice services"
        // textBB2="▣ Network Management Services"
        subtitle1="NTC Digital has a three different model for providing managed services to its customer."
        text1="✓ The Onsite Model that includes providing the qualified onsite resources on Time & Material basis to customers. The resources are supported by NTC Digital experts as and when required."
        text1A="✓ The Offshore-Model that includes 24x7 support from  NTC Digital remote center in India & Canada. This includes proactive monitoring by  NTC Digital engineers remotely. The local hands & feet support will be provided by  NTC Digital engineers on call basis."
        text1B="✓ The Hybrid Model that includes mix of onsite resources and offshore remote management."
        // subtitle2="Network and IT Security Operations"
        // text2="LAN/WAN Administration"
        // text2A="Security Operation Support/ Firewall Administration"
        // text2B="Policy Design, Implementation and administration"
        // subtitle3="Assessment and Scope"
        // text3="The success of a Data Center design or relocation is achieved with clearly defined objectives
        //        and requirements for work, resources, products, schedules and cost."
        // subtitle4="Co-Locations"
        // text4="NTC offers a complete range of Engineering and Implementation Solutions that lower you Data
        //        Center design cost. "
        // subtitle5="Redesign & Relocations"
        // text5="As your technology needs change, NTC consultants facilitate a cost-effective redesign of your
        //        Data Center."
        // subtitle6="Consolidation"
        // text6="We provide consolidation planning services using industry-standard best practices that maximize
        //        efficiencies and dramatically reduce Data Center real estate costs."
        // btnText="Read more"
      />



    <Footer />
  </>
);
};

export default ServicePage3;