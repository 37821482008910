import styled from "styled-components";

export const FooterContainer = styled.div`
    padding-top: 30px;
    padding-bottom: 15px;
    background: linear-gradient(#1CB5E0, #000046);

`

export const FooterWrapper = styled.div`
    max-width: 900px;


`

export const FooterH2 = styled.h2`
    font-size: 1.7rem;
    color: black;
    font-weight: 500;
    margin-bottom: 10px;

`

export const FooterLink = styled.a`
    font-size: 1rem;
    text-decoration: none;
    color: #fff;
    display: flex;
    transition: all 0.3s;

    &:hover {
        color: #B05FFD;
    }

`