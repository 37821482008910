import styled from "styled-components";

export const InfoContainer = styled.div`
  width: 100%;
  min-height: 700px;
  justify-content: center;
  display: flex;
  // background: #faf9ed;
  background-image:linear-gradient(#ffffff, #f5f5f5, #ffffff);
`;

export const InfoWrapper = styled.div`
  display: flex;
  padding: 80px 0;
  width: 1200px;
  justify-content: center;
  align-items: center;
  .imgh {
    // height: 700px;
    // width: 630px;
    margin-top: 10px;
    // border: 20px solid #5e490e;
  }
  .para {
    text-align: justify;
    font-size: 1.176em;
    color:#129dc4;
  }

  .t-skew {
    @media screen and (min-width: 1200px) {
      transform: skew(-15deg);
    }
  }
  .t-skew:hover {
    transform: skew(0deg);
  }
`;

export const Title = styled.h1`
  font-weight: 900;
  // margin-left: 60px;
  // font-size: 3em;
  color: #bd7c1a;
`;

export const Subtitle = styled.h5`
  color: #b05ffd;
  font-weight: 500;
  text-align: right;
  text-transform: uppercase;
`;
export const imgg = styled.div`
  display: flex;
  padding: 80px 100;
  margin: 80px 100;
  justify-content: center;
  align-items: center;
`;
