import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import { InfoContainer, InfoWrapper, Title } from "./infoElements";
import { ServicesContainer, ServicesWrapper, ServicesCard, ServicesH2, ServicesP } from "./ServicesElements";
export const Contact = ({
  id,
  title,
  image,
}
) => {
  return (
    <>
      <InfoContainer id={id}>
        <InfoWrapper>
          <div className="row expand-row gx-3">

            <div className="col-lg-12 col-sm-12 my-auto">
              <ServicesContainer>
                <Title className="">{title}</Title>
                <ServicesWrapper>

                  <ServicesCard>
                    <ServicesH2>ICT, IOT & Security</ServicesH2>
                    <ServicesP className="pColor">
                    VP Business Development
                    </ServicesP>
                    <ServicesP>Zainulabedin Chaviwala</ServicesP>
                    <ServicesP>zenul.chaviwala@ntcdigital.net</ServicesP>
                    <ServicesP>+966 11 261 5060</ServicesP>
                  </ServicesCard>
                  
                  <ServicesCard>
                    <ServicesH2>Sales</ServicesH2>
                    <ServicesP className="pColor">
                     VP Sales 
                    </ServicesP>
                    <ServicesP >Wael Othman</ServicesP>
                    <ServicesP>wael@ntcdigital.net</ServicesP>
                    <ServicesP>+966 11 261 5013</ServicesP>
                  </ServicesCard>

                  <ServicesCard className="">
                    <ServicesH2>Other Inquires</ServicesH2>
                    <ServicesP>
                      Direct Line: +966 11 400 1400
                    </ServicesP>
                    <ServicesP>
                      Email: Info@ntcdigital.net
                    </ServicesP>
                    <ServicesP>
                      Call Center: 9200 18 444
                    </ServicesP>
                    <ServicesP>
                      Fax: +966 11 400 7005
                    </ServicesP>
                  </ServicesCard>

                </ServicesWrapper>
              </ServicesContainer>
            </div>
            {/* <div className="col-lg-6 col-sm-12 img-wrap">
              <img src={image} alt="" className="fit-img no-img no-img1"></img>
            </div> */}
          </div>
        </InfoWrapper>
      </InfoContainer>
      <div>
        <iframe title={"a"} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3061.353614607797!2d46.707855014437875!3d24.77214505489362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2efd9e0d25a049%3A0xb2a128a9144e38a!2z2LTYsdmD2Kkg2KfZhNmG2LfYp9mCINin2YTYs9i52YjYr9mK2Kkg2YTZhNil2KrYtdin2YTYp9iqINmI2KrZgtmG2YrYqSDYp9mE2YXYudmE2YjZhdin2Ko!5e1!3m2!1sen!2ssa!4v1671515070870!5m2!1sen!2ssa" width={100 + "%"} height={500} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </>
  );
};
export default Contact;
