import React, { useState } from "react";
import InfoSection from "../components/infoSection";
import { NavbarBusiness } from "../components/navbar/indexBusiness";
import { SidebarB } from "../components/sidebar/indexB";

import Image4 from "../images/ep.jpeg";
import Footer from "../components/footer";



const Busi2 = () => {
    const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
    <SidebarB isOpen={isOpen} toggle={toggle} />
    <NavbarBusiness toggle={toggle} />
   


    <InfoSection
      image={Image4}
      id="/home"
      subtitle=""
      title="Enterprise Voice Solutions"
      text="Enterprise Voice Solutions: This includes supply and implementation for complex Voice solutions
            for small, medium and large enterprises. NTC Digital has partnered with the best of all the
            technology providers in the industry to provide the solution across the following areas:" 
      textA="✓ IP Telephony"
      text1B="✓ Unified Communications"
      text2C="✓ Conferencing - Audio, Video & the Web"
      text3D="✓ Contact Centers"
                  

      // btnText="Start today"
    />

   
    <Footer />
  </>
);
};

export default Busi2;