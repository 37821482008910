import React from "react";
import {
  FooterContainer,
  FooterH2,
  FooterLink,
  FooterWrapper,
} from "./FooterElements";
import "./../../App.css";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper className="container py-5">
        <div className="row gx-5 px-4 gy-4">
          <div className="col-lg-3 col-sm-6">
            <FooterH2>Contact Us</FooterH2>
            <FooterLink >Info@ntcdigital.net</FooterLink>
            <FooterLink >+966114001400</FooterLink>
            <FooterLink >  9200 18 444</FooterLink>
           
          </div>
          
          <div className="col-lg-3 col-sm-6">
            <FooterH2>Business</FooterH2>
            <FooterLink href="/dc">DC and DR Solutions</FooterLink>
            <FooterLink href="/evs">Enterprise Voice</FooterLink>
            <FooterLink href="/cloud_solution">Clouding Solutions</FooterLink>
          </div>
          <div className="col-lg-3 col-sm-6">
            <FooterH2>Infrastructure</FooterH2>
            <FooterLink href="/ens">Enterprise Networks</FooterLink>
            <FooterLink href="/SmartCity">Physical Security</FooterLink>
            <FooterLink href="/Cabling">Structured Cabling</FooterLink>
          </div>
          <div className="col-lg-3 col-sm-6">
            <FooterH2>Follow Us</FooterH2>
            <FooterLink href="https://twitter.com/ntcdigital">Twitter</FooterLink>
            <FooterLink href="https://www.facebook.com/NTCDIGITALKSA">Facebook</FooterLink>
            <FooterLink href="https://www.linkedin.com/company/ntc-digital">LinkedIn</FooterLink>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-5 ">
            <p className="logo-text gray-text center-align">Copyright © 2018 NTC Digital. All Rights Reserved</p>
          </div>
        </div>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
