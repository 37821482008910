import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import { InfoContainer, InfoWrapper, Subtitle, Title } from "./infoElements";
// import { ButtonBasicInv } from "../ButtonElements";

export const InfoSectionLight = ({
  id,
  title,
  subtitle,
  text,
  textA,
  textB,
  textC,
  textAA,
  textBB,
  textCC,
  textAA1,
  textAAA,
  textBBA,
  textCCA,
  textAA1A,
  textBB2,
  subtitle1,
  subtitle1a,
  text1,
  text1A,
  text1B,
  subtitle2,
  text2,
  text2A,
  text2B,
  subtitle3,
  text3,
  subtitle4,
  text4,
  subtitle5,
  text5,
  subtitle6,
  text6,
  image,
  imageA,
  textAA1AA,
  textBB2A,
  // btnText,
}) => {
  return (
    <>
      <InfoContainer id={id} className="bg-white">
        <InfoWrapper>
          <div className="row expand-row gx-5">
              
            <div className="col-lg-6 col-sm-12 my-auto">
              <Subtitle>{subtitle}</Subtitle>
              <Title className="text-black mb-2">{title}</Title>
              <p className="text-black mb-2 para">{text}</p>
              <p className="text-black mb-2 para">{textA}</p>
              <p className="text-black mb-2 para">{textB}</p>
              <p className="text-black mb-2 para">{textC}</p>
              <ul>
                <p className="text-black mb-2 para">{textAA}</p>
                <p className="text-black mb-2 para">{textBB}</p>
                <p className="text-black mb-2 para">{textCC}</p>
                <p className="text-black mb-2 para">{textAA1}</p>
                <p className="text-black mb-2 para">{textBB2}</p>
              </ul>
              <Subtitle>{subtitle1a}</Subtitle>
              <ul>
                <p className="text-black mb-2">{textAAA}</p>
                <p className="text-black mb-2">{textBBA}</p>
                <p className="text-black mb-2">{textCCA}</p>
                <p className="text-black mb-2">{textAA1A}</p>
                <p className="text-black mb-2">{textAA1AA}</p>
                <p className="text-black mb-2">{textBB2A}</p>
              </ul>
              {/* <ButtonBasicInv to="" primary="false" dark="true" className="mx-auto">
                {btnText}
              </ButtonBasicInv> */}
              <Subtitle>{subtitle1}</Subtitle>
              <p className="text-black mb-2 para">{text1}</p>
              <p className="text-black mb-2 para">{text1A}</p>
              <p className="text-black mb-2 para">{text1B}</p>
              <Subtitle>{subtitle2}</Subtitle>
              <p className="text-black mb-2 para">{text2}</p>
              <p className="text-black mb-2 para">{text2A}</p>
              <p className="text-black mb-2 para">{text2B}</p>
              <Subtitle>{subtitle3}</Subtitle>
              <p className="text-black mb-2 para">{text3}</p>
              <Subtitle>{subtitle4}</Subtitle>
              <p className="text-black mb-2 para">{text4}</p>
              <Subtitle>{subtitle5}</Subtitle>
              <p className="text-black mb-2 para">{text5}</p>
              <Subtitle>{subtitle6}</Subtitle>
              <p className="text-black mb-2 para">{text6}</p>
            </div>

            <div className="col-lg-6 col-sm-12 img-wrap ">
                <img src={image} alt="" className="fit-img shadow p-1 mt-5 bg-light rounded"></img>
                <img src={imageA} alt="" className="fit-img"></img>
            </div>

          </div>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSectionLight;
