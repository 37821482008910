import React, { useState } from "react";
import { NavbarInfra } from "../components/navbar/indexInfra";
import { SidebarI } from "../components/sidebar/indexI";
import Image4 from "../images/enterprise-network-solution.jpg";
import InfoSectionLight from "../components/infoSectionLight";
import Footer from "../components/footer";



const Infra1 = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);

  };

  return (
    <>
    <SidebarI isOpen={isOpen} toggle={toggle} />
    <NavbarInfra toggle={toggle} />
 
    <InfoSectionLight
        image={Image4}
        // id="Contact"
        // subtitle="Duis et lectus accumsan"
        title="Enterprise Network Solutions"
        text="NTC Digital has partnered with the best of all the technology
              providers in the industry to provide the solution across the areas of LAN, WAN and Enterprise Security Solutions. NTC Digital  has the following offerings across the domains:"
        // textAA="✓ Local Area Network (LAN)"
        // textBB="✓ Wide Area Network (WAN)"
        // textCC="✓ Enterprise Security Solutions"
        // subtitle1a="NTC Digital  has the following offerings across the domains:"
        textAAA="◓ Architecture Design"
        textBBA="◓ Equipments Supply"
        textCCA="◓ Installation & Integration"
        textAA1A="◓ Project Management"
        // textAA1AA="◓ Network Consolidation"
        textBB2A="◓ Network Optimization"

        //        Upgrades/Updates/Migrations"
        // text1A="Level 1/Level 2 and Level 3 Skill Support"
        // // text1B="Phone: +966 11 261 5060"
        // subtitle2="Network and IT Security Operations"
        // text2="LAN/WAN Administration"
        // text2A="Security Operation Support/ Firewall Administration"
        // text2B="Policy Design, Implementation and administration"
        // subtitle3="Assessment and Scope"
        // text3="The success of a Data Center design or relocation is achieved with clearly defined objectives
        //        and requirements for work, resources, products, schedules and cost."
        // subtitle4="Co-Locations"
        // text4="NTC offers a complete range of Engineering and Implementation Solutions that lower you Data
        //        Center design cost. "
        // subtitle5="Redesign & Relocations"
        // text5="As your technology needs change, NTC consultants facilitate a cost-effective redesign of your
        //        Data Center."
        // subtitle6="Consolidation"
        // text6="We provide consolidation planning services using industry-standard best practices that maximize
        //        efficiencies and dramatically reduce Data Center real estate costs."
        // btnText="Read more"
      />



    <Footer />
  </>
);
};

export default Infra1;