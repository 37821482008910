import styled from "styled-components";

export const InfoContainer = styled.div `
    width: 97%;
    min-height: 300px;    
    justify-content: center;  
    display: flex;
    // background: linear-gradient(#ffffff, #bce1e3, #ffffff);
    background-image:linear-gradient(90deg,#ffffff, #f5f5f5, #ffffff);
    @media screen and (max-width: 768px){
    .text-org{
        height: 120%;
    }
}



@media screen and (min-width: 768px){
    .mar{
        margin-left: 80px;
    }

}
 

`

export const InfoWrapper = styled.div `
    display: flex;
    padding: 80px 0;
    width: 1200px;
    justify-content: center;
    align-items: center;

   
      
  
`

export const Title = styled.h3 `
    font-weight: 1000;
    color:#bd7c1a;
    margin-left: 20px;
    // margin-top: 3px;
    // text-shadow: 2px 2px #bd7c1a;
    // text-shadow: 2px 2px 4px #000;

    &:hover{
        text-shadow: 2px 2px 4px #9dbffa;
        transform: scale(1.2);
    }

`

export const Subtitle = styled.h5 `
    color: #B05FFD;
    font-weight: 500;
    text-transform: uppercase;
    margin-left: 20px;
    // margin-top: 3px;
`
