import React from "react";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./InfoElements";
import Icon1 from "./nurve.png";
import Icon2 from "./alied-digital.png";
import { ButtonRN } from "../ButtonElements";

const CardP = () => {
  return (
    <ServicesContainer id="about">
      <ServicesH1>Joint Ventures</ServicesH1>
      <ServicesWrapper>
      <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Our Bussiness Consultant</ServicesH2>
          <ServicesP>
          We've partnered with Nurve Partners as our dedicated consultant, collaborating closely to harness fresh perspectives and innovative solutions. Their specialized and comprehensive suite of tailored services, spanning market analysis and growth strategies, will undoubtedly be a pivotal driving force behind our continued and resounding success...
          </ServicesP>

          
          <a className="td" href="https://www.nurvepartners.com/" target="_blank" rel="noreferrer">
          <ButtonRN  primary="true" dark="true">
          Learn More
          </ButtonRN> 
          </a>
         
        </ServicesCard>
        
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>IT Technology Consultant</ServicesH2>
          <ServicesP>
            Allied Digital: Global IT Transformation Architect, offering
            end-to-end services in 35 countries, including a successful joint
            venture with us...
          </ServicesP>

          
          <a   className="td" href="https://www.allieddigital.net/in/" target="_blank" rel="noreferrer">
          <ButtonRN primary="true" dark="true">
            Learn More
          </ButtonRN>
          </a>
        
        </ServicesCard>


      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default CardP;
