import styled from "styled-components";

export const ServicesContainer = styled.div`
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background:  #808080;
    padding: 5px 0;

    .scale{
        transform: scale(1.1);
    }

    @media screen and (max-width: 768px) {
        min-height: 900px;
        .noscale{
            transform: scale(1);
        }
    }

    @media screen and (max-width: 480px) {
        min-height: 900px;
        .noscale{
            transform: scale(1);
        }
    }

`

export const ServicesWrapper = styled.div`
    max-width: 100%;
    // margin: 0px 5px 15px 4px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 20px 50px;


    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
        padding: 20px 50px;
        grid-gap: 16px;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        //padding: 0 20px;
        padding: 20px 50px;
        grid-gap: 16px;
    }

`

export const ServicesCard = styled.div`
    background: linear-gradient(#BA8B02, #181818);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    // border-radius: 10px;
    min-height: 300px;
    min-width: 300px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    @media screen and (max-width: 480px) {
        max-height: 350px;
    }


    

    &:hover {
        transform: scale(1.3);
        background-image:linear-gradient(#c2e59c,#64b3f4,#fcb045);
        box-shadow: 0 8px 20px 0 rgba(0,0,0,0.2);
        -webkit-transition:all 0.5s ease 0s;
        transition:all 0.5s ease 0s;
    }

`


export const ServicesH2 = styled.h2`
    font-size: 1.4rem;
    font-weight: 900;
    margin-bottom: 20px;
    color: #000;

    @media screen and (max-width: 480px) {
        font-size: 0.81rem;
    }

    
`

export const ServicesP = styled.p`
    font-size: 1rem;
    text-align: justify;
    justify-content: center;
    color: #fff;
    &:hover{
       color: var(--smboxShadow) 
    }



    @media screen and (max-width: 480px) {
        font-size: 0.75rem;
    }

`
