import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import { InfoContainer, InfoWrapper, Title } from "./homeInfoElements3";
// import { ButtonBasicInv } from "../ButtonElements";

export const HomeInfo3 = ({
  id,
  title,
  title1,
  title2,
  title3,
  title4,
  title5,
  image,
  image1,
  image2,
  image3,
  image4,
  image5,
  // btnText,
}) => {
  return (
    <>
      <InfoContainer id={id}>
        <InfoWrapper>
          <div className="row expand-row gx-5">
            <div className="col-lg-4 col-sm-12 my-auto mt-5">
              <Title className="mart">
                {title}
              </Title>
            </div>

            <div className="col-lg-8 col-sm-12 mb-5">
              <img
                src={image}
                alt=""
                className=" text-org fit-img mb-3 shadow-lg p-2 rounded img-wrap img-wrap2  mar"
              ></img>
            </div>

            <div className="col-lg-4 col-sm-12 my-auto mt-5">
              <Title className="">
                {title1}
              </Title>
            </div>

            <div className="col-lg-8 col-sm-12 mb-5">
              <img
                src={image1}
                alt=""
                className="text-org  fit-img mb-3 shadow-lg p-2 rounded elem  img-wrap img-wrap2   mar"
              ></img>
            </div>
            <div className="col-lg-4 col-sm-12 my-auto mt-5">
              <Title className="">
                {title2}
              </Title>
            </div>

            <div className="col-lg-8 col-sm-12 mb-5">
              <img
                src={image2}
                alt=""
                className="text-org fit-img mb-3 shadow-lg p-2 rounded elem  img-wrap img-wrap2 mar"
              ></img>
            </div>

            <div className="col-lg-4 col-sm-12 my-auto mt-5">
              <Title className="">
                {title3}
              </Title>
            </div>

            <div className="col-lg-8 col-sm-12 mb-5">
              <img
                src={image3}
                alt=""
                className="text-org fit-img mb-3 shadow-lg p-2 rounded elem  img-wrap img-wrap2 mar "
              ></img>
            </div>

            <div className="col-lg-4 col-sm-12 my-auto mt-5">
              <Title className="">
                {title4}
              </Title>
            </div>

            <div className="col-lg-8 col-sm-12 mb-5">
              <img
                src={image4}
                alt=""
                className="text-org fit-img mb-3 shadow-lg p-2 rounded elem  img-wrap img-wrap2 mar"
              ></img>
            </div>

            <div className="col-lg-4 col-sm-12 my-auto mt-5">
              <Title className="">
                {title5}
              </Title>
            </div>

            <div className="col-lg-8 col-sm-12 mb-5">
              <img
                src={image5}
                alt=""
                className="text-org fit-img mb-3 shadow-lg p-2 rounded elem  img-wrap img-wrap2 mar"
              ></img>
            </div>
          </div>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default HomeInfo3;