import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import { InfoContainer, InfoWrapper, Subtitle, Title } from "./InfoElements";
// import { ButtonBasic } from "../ButtonElements";

export const InfoSection = ({ id, title, subtitle, text, text1, text2, text3,textA, text1B, text2C, text3D, text4, image,ImageNTC, btnText }) => {
  return (
    <>
      <InfoContainer id={id} className="bg-white">
        <InfoWrapper>
          <div className="row expand-row gx-5">
            <div className="col-lg-6 col-sm-12 my-auto"> 
              <Title className="text-black mb-3">{title}</Title>
              <p className="text-black mb-2 para">{text}</p>
    
              <ul>
              <p className="text-black mb-2 para">{textA}</p>
              <p className="text-black mb-2 para">{text1B}</p>
              <p className="text-black mb-2 para">{text2C}</p>
              <p className="text-black mb-2 para">{text3D}</p> 
              <p className="text-black mb-2 para">{text1}</p>
              <p className="text-black mb-2 para">{text2}</p>
              <p className="text-black mb-2 para">{text3}</p>
              <p className="text-black mb-2 para">{text4}</p>
              </ul>
              <Subtitle>{subtitle}</Subtitle>
              {/* <ButtonBasic to="" primary="true" dark="true" className="mx-auto">
                {btnText}
              </ButtonBasic> */}
            </div>
            <div className="col-lg-6 col-sm-12 img-wrap " >
              <img src={image} alt="" className="fit-img shadow p-1 mt-5 bg-light rounded" ></img> 
              <img src={ImageNTC} alt="" className="fit-img"></img>
            </div>
          </div>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
