import React, { useState } from "react";
// import Video3 from "./../videos/video.mp4";
// import HeroSection from "../components/heroSection";
// import InfoSection from "../components/infoSection";
import { NavbarInfra } from "../components/navbar/indexInfra";
import { SidebarI } from "../components/sidebar/indexI";

// import Image1 from "../images/cable.svg";
import Image2 from "../images/ii.jpg";
// import Image3 from "../images/message.svg";
// import Image4 from "../images/network.svg";
// import Image5 from "../images/partners1.jpg";
// import Image6 from "../images/partners2.jpg";
// import Image7 from "../images/busilogo.gif";
import InfoSectionLight from "../components/infoSectionLight";
import Footer from "../components/footer";


const ServicePage2 = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);

  };

  return (
    <>
    <SidebarI isOpen={isOpen} toggle={toggle} />
    <NavbarInfra toggle={toggle} />
    {/* <HeroSection videoId={Video3}
      textH="Infrastructure Solutions"
      textP="With our expertise  in Structured Cabling, Enterprise Network
             and Physical Security for Smart City, We provides you the best solutions"
    /> */}
    <InfoSectionLight
        image={Image2}
        // id="Contact"
        // subtitle="Duis et lectus accumsan"
        title="Infrastructure Integration"
        text="We help our clients to assess and deploy emerging as well as established technologies.
         We balance the risk and opportunity of technology adoption to ensure that the right technology
        investment produces the desired business benefits."
        textA="We have assisted our clients in evaluating core, edge, and access networking technologies creating
         an infrastructure that complements their existing investments (at least in the short run). Every service 
         provider’s situation is unique and the roadmap to evolve to a fully converged broadband IP infrastructure 
         has to be diligently planned as competing technology vendors are pushing for their specific agendas. 
         Our independence neutralizes any vendor bias in creating the right technology roadmap for our clients."
        // textB="Wide Area Network (WAN)"
        // textC="Enterprise Security Solutions"
        // subtitle1="Administration of servers :Windows/Unix/Solaris/AIX/Linux"
        // text1="OS Administration, Patch Maintenance and Monitoring
        //        Upgrades/Updates/Migrations"
        // text1A="Level 1/Level 2 and Level 3 Skill Support"
        // // text1B="Phone: +966 11 261 5060"
        // subtitle2="Network and IT Security Operations"
        // text2="LAN/WAN Administration"
        // text2A="Security Operation Support/ Firewall Administration"
        // text2B="Policy Design, Implementation and administration"
        // subtitle3="Assessment and Scope"
        // text3="The success of a Data Center design or relocation is achieved with clearly defined objectives
        //        and requirements for work, resources, products, schedules and cost."
        // subtitle4="Co-Locations"
        // text4="NTC offers a complete range of Engineering and Implementation Solutions that lower you Data
        //        Center design cost. "
        // subtitle5="Redesign & Relocations"
        // text5="As your technology needs change, NTC consultants facilitate a cost-effective redesign of your
        //        Data Center."
        // subtitle6="Consolidation"
        // text6="We provide consolidation planning services using industry-standard best practices that maximize
        //        efficiencies and dramatically reduce Data Center real estate costs."
        // btnText="Read more"
      />


    <Footer />
  </>
);
};

export default ServicePage2;