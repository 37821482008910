import styled from "styled-components";

export const InfoContainer = styled.div `
    width: 100%;
    min-height: 700px;    
    justify-content: center;  
    display: flex;
    // background-image:linear-gradient(#ffffff, #d0f1f2, #ffffff);
    background-image:linear-gradient(#ffffff, #f5f5f5, #ffffff);

`

export const InfoWrapper = styled.div `
    display: flex;
    padding: 80px 0;
    width: 1200px;
    justify-content: center;
    align-items: center;
    .imgh{
        height: 600px;
        // width: 630px;
        margin-top: 45px;
        // border: 20px solid #5e490e;
      };
    .para{
        text-align: justify;
        font-size: 1.12em;
        color:#129dc4;
    }
    .para1{
        text-align: right;
        // font-size: 1.12em;
    }

    @media screen and (min-width: 1200px){

    .divi{
        position:relative;
    }

    .divs{
        position: absolute;
        height: 565px;
        width: 565px;
        right: 9px;
        top: -6px;
        z-index:-1

}
 
`

export const Title = styled.h1 `
    font-weight: 900;
    // font-size: 3em;
    color: #bd7c1a;
    

`

export const Subtitle = styled.h5 `
    color: #129dc4;
    font-weight: 500;
    text-align: right;
    text-transform: uppercase;

`
export const imgg = styled.div `
    display: flex;
    padding: 80px 100;
    margin: 80px 100;
    justify-content: center;
    align-items: center;

`
