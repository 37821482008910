import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'

export const Nav = styled.nav`
    background: #FFFFFF;
    background: ${({scrollNav}) => (scrollNav ? '#FFFFFF' : 'transparet')};
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: Start;
    align-items: center;
    font-size: 1rem; 
    position: sticky;
    top: 0;
    z-index: 10;
    transition: all 0.3s ease-in-out;

    .nav-logo-img{
        margin-top: 5px;
        width: 160px;
        height: 70px;
    };

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`
export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;

`
export const NavLogo = styled(LinkR)`
    //color: #B05FFD;
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    // margin-left: 10px;
    font-weight: bold;
    text-decoration: none;
    transition: all ease 0.2s;

    &:hover {
        color: #B05FFD;
    }

`

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        //position: absolute;
        //top: 0;
        //right: 0;
        margin-top: auto;
        margin-bottom: auto;
        //transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #000;
    }

`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;
    height: 80px;

    @media screen and (max-width: 768px) {
        display: none;
    }

`

export const NavItem = styled.li`
    height: 80px;

`

export const NavLinks = styled(LinkS)`
    color: #000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &.active {
        border-bottom: 3px solid #B05FFD;
    }

    &:hover {
        color: #B05FFD;
    }

`

export const NavLink = styled(LinkR)`
    color: #000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &.active {
        border-bottom: 3px solid #B05FFD;
    }

    &:hover {
        color: #B05FFD;
    }

    .Dropdown1:hover .Dropdown-menu {
        display: block;
        margin-top: 0; // remove the gap so it doesn't close
     }

`
export const NavLin = styled.div`
    color: #000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &.active {
        border-bottom: 3px solid #B05FFD;
    }

    &:hover {
        color: #B05FFD;
    }

    
    .dropdown:hover .dropdown-menu {
        display: block;
        margin-top: 0; // remove the gap so it doesn't close
     }

`

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    @media screen and (max-width: 768px) {
        display: none;
    }

`

export const NavBtnLink = styled(LinkR)`
    border-radius: 23px;
    text-decoration: none;
    background: #8edeb9;
    white-space: nowrap;
    padding: 10px 36px;
    margin: 10px 0px 10px 60px;
    color: #010606;
    font-size: 1.2rem;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-weight: 900;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #B05FFD;
    }

`
export const A = styled.a`
    border-radius: 23px;
    text-decoration: none;
    background: #8edeb9;
    white-space: nowrap;
    padding: 10px 36px;
    margin: 10px 0px 10px 60px;
    color: #010606;
    font-size: 1.2rem;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-weight: 900;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #B05FFD;
    }

`