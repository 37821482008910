import React, { useState } from "react";
import InfoSection from "../components/infoSection";
import { NavbarBusiness } from "../components/navbar/indexBusiness";
import { SidebarB } from "../components/sidebar/indexB";

import Image7 from "../images/DC4.png";
import Footer from "../components/footer";


const Busi1 = () => {
    const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
    <SidebarB isOpen={isOpen} toggle={toggle} />
    <NavbarBusiness toggle={toggle} />
  

<InfoSection
      image={Image7}
      id="/home"
      subtitle=""
      title="DC and DR Solutions "
      text="NTC Digital offers end-to-end Data Center Management services that provide organizations with
            environmental, networking, and physical capabilities for everything from desk-side computing to
            mission-critical server systems are the followings: "
      textA="◓ Administration of servers (Windows/Unix/Solaris/AIX/Linux)"
      text1B="◓ Network and IT Security Operations"
      text2C="◓ Assessment and Scope"
      text3D="◓ Co-Locations"
      text1="◓ Redesign & Relocations"
      text2="◓ Power Solutions"
      // text3="✓ Smart Water & Leakage Detections"
      // text4="✓ Smart Audio & Visuals"
                  

      // btnText="Start today"
    />


    <Footer />
  </>
);
};

export default Busi1;