import styled from "styled-components";

export const InfoContainer = styled.div `
    width: 100%;
    min-height: 700px;    
    justify-content: center;  
    display: flex;
    background-image:linear-gradient(90deg,#ffffff, #f5f5f5, #ffffff);
    .no-img1{
        transform: scale(1);
        margin-top: 40px;
    }

    .pColor{
        font-weight: 900;
        font-size: 1.03rem;
        color: #fff;
    }


    @media screen and (max-width: 700px)  {
        .no-img{
            width: 0px;
            height: 0px;
        }
}
`

export const InfoWrapper = styled.div `
    display: flex;
    width: 1200px;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 480px) {
        padding: 80px 0;

`

export const Title = styled.h1 `
    font-weight: 900;
    color: #bd7c1a;
    
   
    
`

export const Subtitle = styled.h4 `
    color: #cc5a02;
    font-weight: 500;
    text-transform: uppercase;
    

`