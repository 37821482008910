import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./Works1.css";
import Sh from "../../img/Solutions.svg";
import Fa from "../../img/zain.svg";
import Mobily from "../../img/Mobily.jpg";
import Sirar from "../../img/Sirar.jpg";
import iot from "../../img/Iot2.jpg";
import TAWAL from "../../img/TAWAL.jpg";
import Salam from "../../img/Salam.jpg";
import stc from "../../img/STC Channels.png";
import dawiyat from "../../img/dawiyat.png";
import go from "../../img/GO.jpg";
// import { themeContext } from "../../Context";
import { motion } from "framer-motion/dist/framer-motion";
import { Link } from "react-scroll";
const Works1 = () => {
  // context
  // const theme = useContext(themeContext);
  // const darkMode = theme.state.darkMode;

  // transition
  return (
    <div className="works" id="discover">
      {/* left side */}
      <div className="w-left">
        <div className="awesome">
          {/* dark Mode */}
          <span >Our Partnered</span>
          <span>Brands & Clients</span>
          <span>
            NTC seeks like-minded partners for prosperous digital future.
            <br />
            Experience a collaborative environment delivering exceptional
            solutions.
            <br />
            Let's work towards a prosperous digital future.
            <br />
            Contact us to learn about partnership opportunities.
          </span>
          <div>
            <Link to="Contact" smooth={false} spy={true}>
              <button className="button s-button">Contact Us</button>
            </Link>
          </div>
          <div
            className="blur s-blur1"
            style={{ background: "#ABF1FF94" }}
          ></div>
        </div>

        {/* right side */}
      </div>
      <Carousel className="d-block w-100">
        <Carousel.Item interval={3000}>
          <div className="w-right">
            <motion.div
              initial={{ rotate: 45 }}
              whileInView={{ rotate: 0 }}
              viewport={{ margin: "-40px" }}
              transition={{ duration: 1, type: "spring" }}
              className="w-mainCircle"
            >
              <div className="w-secCircle">
                <img className="w-mainCircleimgdawiyat" src={stc} alt="" />
              </div>
              <div className="w-secCircle">
                <img className="w-mainCircleimg" src={Sirar} alt="" />
              </div>
              <div className="w-secCircle">
                <img className="w-mainCircleimgstcs" src={iot} alt="" />
              </div>
              <div className="w-secCircle">
                <img className="w-mainCircleimg" src={Sh} alt="" />
              </div>
            </motion.div>
            {/* background Circles */}
            {/* <div className="w-backCircle blueCircle"></div>
            <div className="w-backCircle yellowCircle"></div> */}
          </div>
        </Carousel.Item>

        <Carousel.Item interval={2000}>
          <div className="w-right">
            <motion.div
              initial={{ rotate: 45 }}
              whileInView={{ rotate: 0 }}
              viewport={{ margin: "-40px" }}
              transition={{ duration: 1, type: "spring" }}
              className="w-mainCircle"
            >
              <div className="w-secCirc">
               
              </div>
              <div className="w-secCircle">
                <img className="w-mainCircleimg" src={TAWAL} alt="" />
              </div>
              
              <div className="w-secCircle">
                <img className="w-mainCircleimgstcs" src={Fa} alt="" />
              </div>
              <div className="w-secCirc">
                
              </div>
              <div className="w-secCircle">
                <img className="w-mainCircleimg" src={go} alt="" />
              </div>
              <div className="w-secCircle">
                <img className="w-mainCircleimgpic" src={dawiyat} alt="" />
              </div>
              <div className="w-secCircle">
                <img className="w-mainCircleimg" src={Salam} alt="" />
              </div>

              <div className="w-secCircle">
                <img className="w-mainCircleimgdawiyat" src={Mobily} alt="" />
              </div>
              
            </motion.div>
            {/* background Circles */}
            {/* <div className="w-backCircle blueCircle"></div>
            <div className="w-backCircle yellowCircle"></div> */}
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Works1;
