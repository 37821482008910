import React, { useState } from "react";
// import Video3 from "./../videos/video.mp4";
// import HeroSection from "../components/heroSection";
import InfoSection from "../components/infoSection";
import { NavbarI } from "../components/navbar/indexI";
import { SidebarI } from "../components/sidebar/indexI";

import Image1 from "../images/sc2.jpg";
import Image2 from "../images/SmartCity.jpg";
// import Image3 from "../images/message.svg";
import Image4 from "../images/enterprise-network-solution.jpg";
// import Image5 from "../images/partners1.jpg";
// import Image6 from "../images/partners2.jpg";
// import Image7 from "../images/busilogo.gif";
import InfoSectionLight from "../components/infoSectionLight";
import Footer from "../components/footer";


const InfraPage = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);

  };

  return (
    <>
    <SidebarI isOpen={isOpen} toggle={toggle} />
    <NavbarI toggle={toggle} />
    {/* <HeroSection videoId={Video3}
      textH="Infrastructure Solutions"
      textP="With our expertise  in Structured Cabling, Enterprise Network
             and Physical Security for Smart City, We provides you the best solutions"
    /> */}
    <InfoSectionLight
        image={Image4}
        // id="Contact"
        // subtitle="Duis et lectus accumsan"
        title="Enterprise Network Solutions"
        text="NTC Digital has partnered with the best of all the technology
              providers in the industry to provide the solution across the areas of LAN, WAN and Enterprise Security Solutions. NTC Digital  has the following offerings across the domains:"
        // textAA="✓ Local Area Network (LAN)"
        // textBB="✓ Wide Area Network (WAN)"
        // textCC="✓ Enterprise Security Solutions"
        // subtitle1a="NTC Digital  has the following offerings across the domains:"
        textAAA="◓ Architecture Design"
        textBBA="◓ Equipments Supply"
        textCCA="◓ Installation & Integration"
        textAA1A="◓ Project Management"
        // textAA1AA="◓ Network Consolidation"
        textBB2A="◓ Network Optimization"

        //        Upgrades/Updates/Migrations"
        // text1A="Level 1/Level 2 and Level 3 Skill Support"
        // // text1B="Phone: +966 11 261 5060"
        // subtitle2="Network and IT Security Operations"
        // text2="LAN/WAN Administration"
        // text2A="Security Operation Support/ Firewall Administration"
        // text2B="Policy Design, Implementation and administration"
        // subtitle3="Assessment and Scope"
        // text3="The success of a Data Center design or relocation is achieved with clearly defined objectives
        //        and requirements for work, resources, products, schedules and cost."
        // subtitle4="Co-Locations"
        // text4="NTC offers a complete range of Engineering and Implementation Solutions that lower you Data
        //        Center design cost. "
        // subtitle5="Redesign & Relocations"
        // text5="As your technology needs change, NTC consultants facilitate a cost-effective redesign of your
        //        Data Center."
        // subtitle6="Consolidation"
        // text6="We provide consolidation planning services using industry-standard best practices that maximize
        //        efficiencies and dramatically reduce Data Center real estate costs."
        // btnText="Read more"
      />


    <InfoSection
      image={Image2}
    //   id="about"
      subtitle=""
      title="Physical Security and Smart City"
      text="NTC Digital is one of the pioneering companies of Saudi that specializes in planning and implementing smart
            city solutions. As urban population cities to swell due to high demand in migration on account of better job
            opportunities, efficient delivery of services to citizens becomes paramount.
            The need for access to quality services and smarter communities is becoming increasingly important every
            passing day.
            By combining our experience in Internet of Things (IoT) with GIS, big data and integrated security
            management technologies, NTC Digital is providing rich experiences to urbanites. We have worked on variety
            of projects with  governments, leading telecom operators, banking institutions and electricity supply
            companies to realize the ambition of smarter cities." 
      // text1="IP Telephony"
      // text2="Unified Communications"
      // text3="Conferencing - Audio, Video & Web"
      // text4="Contact Centers"
                  

      // btnText="Start today"
    />

    <InfoSectionLight
      image={Image1}
    //   id="discover"
      // subtitle="infrastructure and management strategy."
      title="Structured Cabling"
      text="In today's quickly evolving business environments, communication systems are a necessity to operate
            a successful business. Moving computers, phone systems or your business may bring with it concerns of 
            downtime which can affect your bottom line. NTC Digital's structured wiring, voice & data and network cabling specialists eliminate these worries by delivering structured network cabling solutions for voice, video and data systems. We specialize in new construction, office relocations, and server room cabling and cleanup. Whether you need cabling for your data network, your phone system or both, we will ensure that you get everything needed for a reliable up-to-date system that will support the newest technologies
            We have established NTC Digital to address this challenge and have the vision and the competence to 
            establish new generation of telecommunication service provider, enterprise and public sector."
      // text3="Creating virtual networks, Building virtual machines"
      // text4="Generating extra storage, so files are easily accessible from anywhere"
      // text5="Backup of critical information and files, or disaster recovery"
      // text6="Implementing large batch computing"
      // btnText="Explore"
    />

    <Footer />
  </>
);
};

export default InfraPage;