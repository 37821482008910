import React from "react";
import CountUp from "react-countup";
// import { themeContext } from "../../Context";
import "./Experience.css";
const Experience = () => {
  // const theme = useContext(themeContext);
  // const darkMode = theme.state.darkMode;

  return (
    <div className="experience" id="experience">
      <div className="achievement">
        {/* darkMode */}
        <div className="circle">
          <CountUp start={0} end={23} duration={2} />
        </div>
        {/* <span></span> */}
        <span>National Locations</span>
      </div>
      <div className="achievement">
        <div className="circle">
          <CountUp start={0} end={200} duration={2} />+
        </div>
        {/* <span  ></span> */}
        <span>Workforce</span>
      </div>
      <div className="achievement">
        <div className="circle">
        <CountUp start={0} end={40} duration={2} />+
          </div>
        {/* <span ></span> */}
        <span>OEM Partners</span>
      </div>
    </div>
  );
};

export default Experience;
