import React from 'react'
import {SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu,  SidebarLinkR, SideBtnWrap, A} from './SidebarElements'
import {  useHistory } from 'react-router-dom';
import * as Scroll from 'react-scroll'; 

export const SidebarB = ({isOpen, toggle}) => {

    // const path = useLocation().pathname;
    // const location = path.split('/')[1];
    const history = useHistory();
    const scroller = Scroll.scroller;

    const goToHomeAndScrollAbout = async () => {
        await closeMobile();
        await history.push('/');
        await scroller.scrollTo('about', {
          duration: 400,
          delay: 10,
          smooth: true,
          // offset: 50,
        });
      };  
    
      const goToHomeAndScrollPartner = async () => {
        await closeMobile();
        await history.push('/');
        await scroller.scrollTo('discover', {
          duration: 400,
          delay: 10,
          smooth: true,
          // offset: 50,
        });
      };  
    
      const goToHomeAndScrollService = async () => {
        await closeMobile();
        await history.push('/');
        await scroller.scrollTo('services', {
          duration: 400,
          delay: 10,
          smooth: true,
          offset: -50,
        });
      };  
    
      const goToHomeAndScrollContact = async () => {
        await closeMobile();
        await history.push('/');
        await scroller.scrollTo('Contact', {
          duration: 400,
          delay: 10,
          smooth: true,
          offset: 100,
        });
      };  
    
      const closeMobile = () => {};

    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLinkR to="/home" onClick={goToHomeAndScrollAbout}>
                        About
                    </SidebarLinkR>
                    <SidebarLinkR to="/home" onClick={goToHomeAndScrollPartner}>
                        Partners
                    </SidebarLinkR>
                    <SidebarLinkR to="/home" onClick={goToHomeAndScrollService}>
                        Services
                    </SidebarLinkR>
                    <SidebarLinkR to="/home" onClick={goToHomeAndScrollContact}>
                        Contact
                    </SidebarLinkR>
                    <SidebarLinkR to="/busi" onClick={toggle}>
                        Bussiness Solutions
                    </SidebarLinkR>
                    <SidebarLinkR to="/infra" onClick={toggle}>
                        Infrastructure Solutions
                    </SidebarLinkR>
                </SidebarMenu>
                <SideBtnWrap>
                    <A href="http://ntcdigitalapp.com/Pages/Login.aspx">Login</A>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}