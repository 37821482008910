import React from "react";
import * as Scroll from 'react-scroll';
import { motion } from "framer-motion/dist/framer-motion";
import "./Intro.css";
import Vector1 from "../../img/Vector1.png";
import Vector2 from "../../img/Vector2.1.png";
import boy from "../../img/boy6.png";    
import glassesimoji from "../../img/crown.png";
import thumbup from "../../img/thumbup.png";
// import telco from "../../img/telco img.png";
import FloatinDiv from "../FloatingDiv/FloatingDiv";
// import Github from "../../img/github.png";
// import LinkedIn from "../../img/linkedin.png";
// import Instagram from "../../img/instagram.png";
// import { themeContext } from "../../Context";
// import { motion } from "framer-motion";
import Experience from "../Experience/Experience"
// import { Link } from "react-scroll";
const Intro = () => {
  // Transition
  const transition = { duration: 2, type: "spring" };
  const scroller = Scroll.scroller;
  const ofst = async () => {
    await scroller.scrollTo('services', {
      duration: 0.1,
      delay: 0.1,
      offset: -40,
    });
  };  

  // context
  // const theme = useContext(themeContext);
  // const darkMode = theme.state.darkMode;

  return (
    <div className="Intro" id="hero">
      {/* left name side */}
      <div className="i-left">
        <div className="i-name">
          {/* yahan change hy darkmode ka */}
          <span>NTC DIGITAL</span>
          <span>Tech Leading Company</span>
          <span>
            We offer IT services to Saudi Arabia's leading companies, helping
            them transform and scale in the digital age. Our expert team
            enables, manages, and scales operations with end-to-end services
            across the digital transformation journey
          </span>
        </div>
        <div>
          <Experience/>
        </div>
          <button className="i-button" onClick={ofst}>Explore More</button>
      </div>
      {/* right image side */}
      <div className="i-right ">
        <img src={Vector1} alt="" />
        <img src={Vector2} alt="" />
        <img src={boy} alt="" />
        {/* animation */}
        <motion.img
          initial={{ left: "-36%" }}
          whileInView={{ left: "-42%" }}
          transition={transition}
          src={""}
          alt=""
        />

        <motion.div
          initial={{ top: "1%", left: "52%" }}
          whileInView={{ left: "68%" }}
          transition={transition}
          className="floating-div"
        >
          <FloatinDiv img={glassesimoji} text1="Well" text2="Qualified" />
        </motion.div>

        {/* animation */}

        <motion.div
          initial={{ left: "9rem", top: "18rem" }}
          whileInView={{ left: "-1rem" }}
          transition={transition}
          className="floating-div"
        >
          {/* floatinDiv mein change hy dark mode ka */}
          <FloatinDiv img={thumbup} text1="Big enough to" text2="deliver" />
        </motion.div>

        {/* <div className="blur" style={{ background: "rgb(238 210 255)" }}></div> */}
        <div
          className="blur"
          style={{
            background: "#C1F5FF",
            top: "17rem",
            width: "21rem",
            height: "11rem",
            left: "-9rem",
          }}
        ></div>
      </div>
    </div>
  );
};

export default Intro;
