import React, { useState } from "react";
import { NavbarInfra } from "../components/navbar/indexInfra";
import { SidebarI } from "../components/sidebar/indexI";
import Image4 from "../images/fftx.png";
import InfoSectionLight from "../components/infoSectionLight";
import Footer from "../components/footer";


const ServicePage1 = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);

  };

  return (
    <>
    <SidebarI isOpen={isOpen} toggle={toggle} />
    <NavbarInfra toggle={toggle} />
    <InfoSectionLight
        image={Image4}
        // id="Contact"
        // subtitle="Duis et lectus accumsan"
        title="FTTx Telecom Services"
        text="Fiber is now gaining acceptance in the final frontier of telephone networks, the last mile, the
              connection to the home. Many homes are still connected with aging, low performance copper telephone
              wire that cannot support connection speeds for broadband access.The costs of maintaining these old
              copper cable plants is also extremely high and increasing."
        textA="Phone companies, cities, utilities and commercial service providers are now realizing the best choice
               for upgrading the subscriber connection is fiber to the premises or home (FTTP, FTTH, or FTTx) 
               although fiber to the curb (FTTC) or fiber to wireless (FTTW) may be used where appropriate.
               The possibility of delivering new services (the triple-play of phone, Internet and video) and low
               priced components for with new network architectures make FTTx financially attractive for the first
               time. Companies are committing billions of dollars to plans for connecting millions of homes and
               offices with fiber."
        // textB="Wide Area Network (WAN)"
        // textC="Enterprise Security Solutions"
        // subtitle1="Administration of servers :Windows/Unix/Solaris/AIX/Linux"
        // text1="OS Administration, Patch Maintenance and Monitoring
        //        Upgrades/Updates/Migrations"
        // text1A="Level 1/Level 2 and Level 3 Skill Support"
        // // text1B="Phone: +966 11 261 5060"
        // subtitle2="Network and IT Security Operations"
        // text2="LAN/WAN Administration"
        // text2A="Security Operation Support/ Firewall Administration"
        // text2B="Policy Design, Implementation and administration"
        // subtitle3="Assessment and Scope"
        // text3="The success of a Data Center design or relocation is achieved with clearly defined objectives
        //        and requirements for work, resources, products, schedules and cost."
        // subtitle4="Co-Locations"
        // text4="NTC offers a complete range of Engineering and Implementation Solutions that lower you Data
        //        Center design cost. "
        // subtitle5="Redesign & Relocations"
        // text5="As your technology needs change, NTC consultants facilitate a cost-effective redesign of your
        //        Data Center."
        // subtitle6="Consolidation"
        // text6="We provide consolidation planning services using industry-standard best practices that maximize
        //        efficiencies and dramatically reduce Data Center real estate costs."
        // btnText="Read more"
      />


   
   

    <Footer />
  </>
);
};

export default ServicePage1;