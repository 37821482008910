import React, { useState } from "react";
import { NavbarBusiness } from "../components/navbar/indexBusiness";
import { SidebarB } from "../components/sidebar/indexB";

import Image1 from "../images/clouds.jpeg";
import InfoSectionLight from "../components/infoSectionLight";
import Footer from "../components/footer";


const Busi3 = () => {
    const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
    <SidebarB isOpen={isOpen} toggle={toggle} />
    <NavbarBusiness toggle={toggle} />

    <InfoSectionLight
      image={Image1}
      id="/home"
      // subtitle1a="infrastructure and management strategy."
      title="Clouding Solutions"
      text="Maintaining servers for your organization requires a lot of unseen overhead and increases your
            Total Cost of Ownership (TCO). Not only does your organization need to invest in the computing 
            hardware, but you must also provide software, licenses, uninterruptible power, a cool secure 
            location, sufficient bandwidth, and trained staff to monitor your investment 24x7x365
            NTC Digital Can Lower your IT Overhead if you Move your IT Equipment to our Global Data Center. 
            Cloud Computing, Hosting, or CoLo of Your IT Equipment can Save You Money. Our Services include:"
      textAAA="◓ Creating virtual networks, Building virtual machines"
      textBBA="◓ Generating extra storage, files are easily accessible easily"
      textCCA="◓ Backup of critical information and files, or disaster recovery"
      // textAA1A="4. Implementing large batch computing"
      // btnText="Explore"
    />

    <Footer />
  </>
);
};

export default Busi3;