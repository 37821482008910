import React, { useState } from "react";
import { NavbarInfra } from "../components/navbar/indexInfra";
import { SidebarI } from "../components/sidebar/indexI";
import data from "./data.json";
import Jobs from "../components/jobs/Jobs";
import Footer from "../components/footer";


const Jobb = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);

  };

  return (
    <>
    <SidebarI isOpen={isOpen} toggle={toggle} />
    <NavbarInfra toggle={toggle} />
    <Jobs
        data={data}
      />

   
   

    <Footer />
  </>
);
  }


export default Jobb;