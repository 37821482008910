import React from "react";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./ServicesElements";
import Icon1 from "../../images/FTTH-removebg.png";
import Icon2 from "../../images/iit.png";
import Icon3 from "../../images/managet.png";
import Icon4 from "../../images/supportt.webp";
import Icon5 from "../../images/sit.png";
import Icon6 from "../../images/dslt.png";
import { ButtonR } from "../ButtonElements";
const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>Our Services</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Managed Services</ServicesH2>
          <ServicesP>
            NTC Digital offers managed services that are, IT Helpdesk Services,
            Data center Services, Managed Network, Managed Voice, Network
            Management Services...
          </ServicesP>

          <ButtonR to="/ManagedServices" primary="true" dark="true">
            Read More
          </ButtonR>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Infrastructure Integration</ServicesH2>
          <ServicesP>
            We have acquired extensive hands on expertise to integrate telecom,
            networking infrastructures to realize the full benefits of M&A
            activities in the telecom sector...
          </ServicesP>

          <ButtonR to="/InfrastructureIntegration" primary="true" dark="true">
            Read More
          </ButtonR>
        </ServicesCard>
        
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>FTTx Telecom Services</ServicesH2>
          <ServicesP>
            Phone companies, cities, utilities and commercial service providers
            are now realizing the best choice for upgrading the subscriber
            connection is fiber...
          </ServicesP>

          <ButtonR to="/FTTx" primary="true" dark="true">
            Read More
          </ButtonR>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon4} />
          <ServicesH2>Support Services</ServicesH2>
          <ServicesP>
            Enterprise Support Services provides High Availability services &
            Sustenance support for product usage life cycle, through proactive
            and reactive services delivered...
          </ServicesP>

          <ButtonR to="/SupportServices" primary="true" dark="true">
            Read More
          </ButtonR>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon src={Icon5} />
          <ServicesH2>System Integration</ServicesH2>
          <ServicesP>
            System integration is a key offering within NTC Digital, this
            includes end to end deployment of solutions for the customer across
            different technology platforms...
          </ServicesP>

          <ButtonR to="/SystemIntegration" primary="true" dark="true">
            Read More
          </ButtonR>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon src={Icon6} />
          <ServicesH2>Consulting Services</ServicesH2>
          <ServicesP>
            We have established NTC Digital to address this challenge and have
            the vision and the competence to establish new generation of
            telecommunication...
          </ServicesP>

          <ButtonR to="/ConsultingServices" primary="true" dark="true">
            Read More
          </ButtonR>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
