import React, { useState } from "react";
import { NavbarInfra } from "../components/navbar/indexInfra";
import { SidebarI } from "../components/sidebar/indexI";

import Image1 from "../images/sc2.jpg";
import InfoSectionLight from "../components/infoSectionLight";
import Footer from "../components/footer";



const Infra3 = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);

  };

  return (
    <>
    <SidebarI isOpen={isOpen} toggle={toggle} />
    <NavbarInfra toggle={toggle} />


    <InfoSectionLight
      image={Image1}
    //   id="discover"
      // subtitle="infrastructure and management strategy."
      title="Structured Cabling"
      text="In today's quickly evolving business environments, communication systems are a necessity to operate
            a successful business. Moving computers, phone systems or your business may bring with it concerns of 
            downtime which can affect your bottom line. NTC Digital's structured wiring, voice & data and network cabling specialists eliminate these worries by delivering structured network cabling solutions for voice, video and data systems. We specialize in new construction, office relocations, and server room cabling and cleanup. Whether you need cabling for your data network, your phone system or both, we will ensure that you get everything needed for a reliable up-to-date system that will support the newest technologies
            We have established NTC Digital to address this challenge and have the vision and the competence to 
            establish new generation of telecommunication service provider, enterprise and public sector."
      // text3="Creating virtual networks, Building virtual machines"
      // text4="Generating extra storage, so files are easily accessible from anywhere"
      // text5="Backup of critical information and files, or disaster recovery"
      // text6="Implementing large batch computing"
      // btnText="Explore"
    />

    <Footer />
  </>
);
};

export default Infra3;