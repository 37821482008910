import React, { useState } from "react";
import InfoSection from "../components/infoSection";
import { NavbarBusiness } from "../components/navbar/indexBusiness";
import { SidebarB } from "../components/sidebar/indexB";
import Image8 from "../images/iotb1.avif";
import Footer from "../components/footer";


const Busi4 = () => {
    const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
    <SidebarB isOpen={isOpen} toggle={toggle} />
    <NavbarBusiness toggle={toggle} />
   


<InfoSection
      image={Image8}
      id="/home"
      subtitle=""
      title="Internet of Things (IoT)"
      text=" NTC Digital has the best expertise in  terms of IoT,  will provide the solution across the following areas:" 
      textA="✓ Smart Buildings & Townships"
      // text1B="✓ Smart Rooms"
      text2C="✓ Energy management"
      text3D="✓ Smart lighting"
      // text1="✓ Asset Management"
      text2="✓ Vehicle Management"
      text3="✓ Smart Offices"
      text4="✓ Integrated Operation Centre"
                  

      // btnText="Start today"
    />


    <Footer />
  </>
);
};

export default Busi4;