import React, { useState } from "react";
// import Video2 from "./../videos/video1.mp4";
// import HeroSection from "../components/heroSection";
import InfoSection from "../components/infoSection";
import { NavbarB } from "../components/navbar/indexB";
import { SidebarB } from "../components/sidebar/indexB";

import Image1 from "../images/clouds.jpeg";
import Image4 from "../images/ep.jpeg";
import Image7 from "../images/DC4.png";
import Image8 from "../images/iotb1.avif";
import Image9 from "../images/aihome3.webp";
import InfoSectionLight from "../components/infoSectionLight";
import Footer from "../components/footer";


const BusinessPage = () => {
    const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
    <SidebarB isOpen={isOpen} toggle={toggle} />
    <NavbarB toggle={toggle} />
    {/* <HeroSection videoId={Video2}
      textH="Bussiness Solutions"
      textP="We provides you the best Data Center and Disaster Recovery Architecture with
             Enterprise Voice Solutions and cloud solutions"
    /> */}
    {/* <InfoSectionLight
        image={Image7}
        id="/home"
        // subtitle="Duis et lectus accumsan"
        title="DC and DR Solutions "
        text="NTC Digital offers end-to-end Data Center Management services that provide organizations with
              environmental, networking, and physical capabilities for everything from desk-side computing to
              mission-critical server systems are the followings: "
        // textA="Direct Line: 	+966 11 400 1400"
        // textB="Fax: +966 11 400 7005"
        // textC="Call Center: 9200 18 444"
        subtitle1="Administration of servers"
        // text1="OS Administration, Patch Maintenance and Monitoring
        //        Upgrades/Updates/Migrations"
        // text1A="Level 1/Level 2 and Level 3 Skill Support"
        // text1B="Phone: +966 11 261 5060"
        subtitle2="Network and IT Security Operations"
        // text2="LAN/WAN Administration"
        // text2A="Security Operation Support/ Firewall Administration"
        // text2B="Policy Design, Implementation and administration"
        subtitle3="Assessment and Scope"
        // text3="The success of a Data Center design or relocation is achieved with clearly defined objectives
        //        and requirements for work, resources, products, schedules and cost."
        subtitle4="Co-Locations"
        // text4="NTC offers a complete range of Engineering and Implementation Solutions that lower you Data
        //        Center design cost. "
        subtitle5="Redesign & Relocations"
        // text5="As your technology needs change, NTC consultants facilitate a cost-effective redesign of your
        //        Data Center."
        // subtitle6="Consolidation"
        // text6="We provide consolidation planning services using industry-standard best practices that maximize
        //        efficiencies and dramatically reduce Data Center real estate costs."
        // btnText="Read more"
      /> */}

<InfoSection
      image={Image7}
      id="/home"
      subtitle=""
      title="DC and DR Solutions "
      text="NTC Digital offers end-to-end Data Center Management services that provide organizations with
            environmental, networking, and physical capabilities for everything from desk-side computing to
            mission-critical server systems are the followings: "
      textA="◓ Administration of servers (Windows/Unix/Solaris/AIX/Linux)"
      text1B="◓ Network and IT Security Operations"
      text2C="◓ Assessment and Scope"
      text3D="◓ Co-Locations"
      text1="◓ Redesign & Relocations"
      text2="◓ Power Solutions"
      // text3="✓ Smart Water & Leakage Detections"
      // text4="✓ Smart Audio & Visuals"
                  

      // btnText="Start today"
    />


    <InfoSection
      image={Image4}
      id="/home"
      subtitle=""
      title="Enterprise Voice Solutions"
      text="Enterprise Voice Solutions: This includes supply and implementation for complex Voice solutions
            for small, medium and large enterprises. NTC Digital has partnered with the best of all the
            technology providers in the industry to provide the solution across the following areas:" 
      textA="✓ IP Telephony"
      text1B="✓ Unified Communications"
      text2C="✓ Conferencing - Audio, Video & the Web"
      text3D="✓ Contact Centers"
                  

      // btnText="Start today"
    />

    <InfoSectionLight
      image={Image1}
      id="/home"
      // subtitle1a="infrastructure and management strategy."
      title="Clouding Solutions"
      text="Maintaining servers for your organization requires a lot of unseen overhead and increases your
            Total Cost of Ownership (TCO). Not only does your organization need to invest in the computing 
            hardware, but you must also provide software, licenses, uninterruptible power, a cool secure 
            location, sufficient bandwidth, and trained staff to monitor your investment 24x7x365
            NTC Digital Can Lower your IT Overhead if you Move your IT Equipment to our Global Data Center. 
            Cloud Computing, Hosting, or CoLo of Your IT Equipment can Save You Money. Our Services include:"
      textAAA="◓ Creating virtual networks, Building virtual machines"
      textBBA="◓ Generating extra storage, files are easily accessible easily"
      textCCA="◓ Backup of critical information and files, or disaster recovery"
      // textAA1A="4. Implementing large batch computing"
      // btnText="Explore"
    />

<InfoSection
      image={Image8}
      id="/home"
      subtitle=""
      title="Internet of Things (IoT)"
      text=" NTC Digital has the best expertise in  terms of IoT,  will provide the solution across the following areas:" 
      textA="✓ Smart Buildings & Townships"
      // text1B="✓ Smart Rooms"
      text2C="✓ Energy management"
      text3D="✓ Smart lighting"
      // text1="✓ Asset Management"
      text2="✓ Vehicle Management"
      text3="✓ Smart Offices"
      text4="✓ Integrated Operation Centre"
                  

      // btnText="Start today"
    />

<InfoSection
      image={Image9}
      id="/home"
      subtitle=""
      title="AI Enabled Smart Home Solutions"
      text=" Smart Homes use AI and IOT technologies such as connected sensors, lighting, and meters to collect and analyze data to make more efficient. NTC Digital has expertise in following solutions:" 
      textA="◓ Smart Electricals"
      text1B="◓ Smart Adaptive Lighting"
      text2C="◓ Smart Home Security"
      text3D="◓ Smart CCTV"
      text1="◓ Smart Environment Control"
      text2="◓ Smart Windows"
      text3="◓ Smart Water & Leakage Detections"
      text4="◓ Smart Audio & Visuals"
                  

      // btnText="Start today"
    />
    <Footer />
  </>
);
};

export default BusinessPage;