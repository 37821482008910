import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import ScrollToTop from "./scrollToTop";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home } from "./pages";
import BusinessPage from "./pages/busi";
import InfraPage from "./pages/infra";
import ServicePage1 from "./pages/service1";
import { Redirect } from "react-router-dom";
import ServicePage3 from "./pages/service3";
import ServicePage4 from "./pages/service4";
import ServicePage5 from "./pages/service5";
import ServicePage6 from "./pages/service6";
import ServicePage2 from "./pages/service2";
import Busi1 from "./pages/busi1";
import Busi2 from "./pages/busi2";
import Busi3 from "./pages/busi3";
import Busi4 from "./pages/busi4";
import Busi5 from "./pages/busi5";
import Infra1 from "./pages/infra1";
import Infra2 from "./pages/infra2";
import Infra3 from "./pages/infra3";

import Jobb from "./pages/jobs";


function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/home" component={Home} exact />
        <Route path="/busi" component={BusinessPage} exact />
        <Route path="/dc" component={Busi1} exact />
        <Route path="/evs" component={Busi2} exact />
        <Route path="/cloud_solution" component={Busi3} exact />
        <Route path="/iot" component={Busi4} exact />
        <Route path="/smart_home" component={Busi5} exact />
        <Route path="/infra" component={InfraPage} exact />
        <Route path="/ens" component={Infra1} exact />
        <Route path="/SmartCity" component={Infra2} exact />
        <Route path="/Cabling" component={Infra3} exact />
        <Route path="/FTTx" component={ServicePage1} exact />
        <Route path="/InfrastructureIntegration" component={ServicePage2} exact />
        <Route path="/ManagedServices" component={ServicePage3} exact />
        <Route path="/SupportServices" component={ServicePage4} exact />
        <Route path="/SystemIntegration" component={ServicePage5} exact />
        <Route path="/ConsultingServices" component={ServicePage6} exact />
        <Route path="/jobs" component={Jobb} exact />
        <Redirect to="/home" />
      </Switch>
    </Router>
  );
}

export default App;
