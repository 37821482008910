import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import { InfoContainer, InfoWrapper,  Subtitle,  Title } from "./infoElements";
// import { ButtonBasic } from "../ButtonElements";

export const InfoSection4 = ({ id, TitleInfo, textInfo, Subtitle1, image}) => {
  return (
    <>
      <InfoContainer id={id}>
        <InfoWrapper>
          <div className="row expand-row gx-5">
            <div className="col-lg-6 col-sm-12 my-auto"> 
                <Title className="mb-3">{TitleInfo}</Title>
                <div className=" shadow p-3 mb-4  rounded">
                  <p className="fst-italic para">{textInfo}</p>
                  <Subtitle className="fst-italic  para1">{Subtitle1}</Subtitle>
                </div>
            </div>
            
            <div className="col-lg-6 col-sm-12 img-wrapceo divi">
              <img src={image} alt="" className="fit-img img-wrap1" ></img>
              <div className="divs"></div>
            </div>
          </div>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection4;
