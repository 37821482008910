import React, { useState } from "react";
// import Video1 from "./../videos/video1.mp4";
// import HeroSection from "../components/heroSection";
// import InfoSection from "../components/infoSection";
import InfoSection2 from "../components/infoSection2";
// import HomeInfo from "../components/homeInfo";
import { Navbar } from "./../components/navbar";
import { Sidebar } from "./../components/sidebar";
import Contact from "../components/contact";

// import Image1 from "../images/jv-na1.png";
// import ImageConsultant from "../images/ntc-consultant.png";
// import Image3 from "../images/ntcpic.png";
// import Image4 from "../images/techPartner.jpg";
// import Image5 from "../images/NBP1.png";
// import Image6 from "../images/NBP22.png";
import Image7 from "../images/cp1.png";
// import Image8 from "../images/inception2.png";
import Image9 from "../images/cctv.png";
import Image10 from "../images/ict.png";
import Image11 from "../images/value2.png";
import Image12 from "../images/CEO pic9.png";
import Image13 from "../images/firwal.png";
import Image14 from "../images/net.png";
import Image15 from "../images/iot.png";
import Image16 from "../images/ps.png";
// import Image8 from "../images/inception3.png";
// import Image9 from "../images/ict.png";
// import InfoSectionLight from "../components/infoSectionLight";
import Services from "../components/services";
import Footer from "../components/footer";
// import HomeInfo2 from "../components/homeInfo2";
import HomeInfo3 from "../components/homeInfo3";
// import InfoSection3 from "../components/infoSection3";
import InfoSection4 from "../components/infoSection4";
import Intro from "../components/Intro/Intro";
// import Works from "../components/Works/Works";
import Works1 from "../components/Works1/Works1";
// import Works2 from "../components/Works2/Works";
import CardP from "../components/cards/CardP";


export const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Intro id="hero" />
      {/* <HeroSection videoId={Video1}
        textH="NTC DIGITAL"
        textP="The Tech Leading Company for Smart Cities"
      /> */}

      {/* <InfoSection3
        image={Image8}
        id="about"
        subtitle=""
        TitleInfo="Inception of NTC"
        textInfo="NTC was created out of the necessity for a System Integrator with Specialization in Telecom & IT Infrastructure in the Kingdom of Saudi Arabia. Since its establishment, NTC has provided clear vision for its customers through providing the highest quality of service by integrating multiple systems and tools with optimal expertise. "
              
        // btnText="Start today"
      /> */}

      {/* <Works /> */}
      <Works1 />
      {/* /<Works2/> */}

      {/* <HomeInfo2
        image={Image5}
        imageA={Image6}
        id="discover"
        subtitle="NTC DIGITAL's"
        title="Bussiness Partners"
        // text="Shahnawaz khan || 0560811571"
        // btnText="Explore"
      /> */}

      <HomeInfo3
        image={Image9}
        image1={Image10}
        image2={Image14}
        image3={Image13}
        image4={Image15}
        image5={Image16}
        id="discover"
        title=" CCTV Partners"
        title1="ICT Infra Partners"
        title2="Networking"
        title3="Firewall Partners"
        title4="IoT & Smart Devices"
        title5="Power Solutions"
      />

      {/* <InfoSection3
        image={Image1}
        id="about"
        subtitle=""
        TitleInfo="Our Joined Venture with Allied Digital"
        textInfo="Allied Digital is well renowned as a leading Global IT Transformation Architect, with an
              impeccable track record for designing, developing, deploying and delivering end-to-end IT 
              Infrastructure services. Allied Digital responsibly delivers cutting-edge IT services and solutions
              to a wide range of industries spanning 35 countries across 5 continents. As a trusted partner
              with wide range of service capabilities and state-of-the-art global command centers, Allied
              Digital help clients transform and succeed in challenging environments by making better  IT 
              decisions."

        // btnText="Start today"
      />

      <InfoSection3
        image={ImageConsultant}
        id="about"
        subtitle=""
        TitleInfo="Nurve Partners: A Trusted Business Consultant"
        textInfo="We're excited to share that we've partnered with Nurve Partners as our dedicated consultant! As a team of seasoned experts, they bring a fresh perspective and  innovative solutions to help us overcome our business challenges and achieve our goals. Their tailored consulting services, including market analysis and growth strategies, will be invaluable in helping us navigate the complexities of the business world and stay ahead of the competition. We're confident that this partnership will drive our success and propel us towards new heights. Let's embark on this journey together and achieve great things! A company specialized in providing commercial, technical and strategic advice to the public and private sectors "

        // btnText="Start today"
      /> */}

      <CardP id="about"/>

      <InfoSection4
        image={Image12}
        // id="about"
        Subtitle1="~Abdullah Alsaber"
        TitleInfo="CEO Message"
        textInfo=" “NTC is your partner for a seamless digital transformation journey. Since 2012, we have been delivering unparalleled IT solutions to help businesses achieve their goals. Our team of highly qualified national experts and strategic partnerships with leading companies enables us to provide you with cutting-edge solutions that exceed your expectations. Our mission is to help you grow locally and globally by integrating multiple systems and tools to deliver exceptional communications and IT services, infrastructure, and consulting services. We are driven by our passion for success, which fuels us to tackle challenges head-on and deliver the highest quality standards in the industry. With NTC as your partner, you can be assured of a seamless journey towards a digital future that is marked by growth and success.” "

        // btnText="Start today"
      />

      <InfoSection2
        image={Image11}
        // id="about"
        TitleInfo1="Vision"
        textInfo1="“Excel in the development, deployment, operation & maintenance of ICT & Telecom solutions in the region measured by customer satisfaction.” "
        TitleInfo="Mission"
        textInfo="“Provide world class integrated solutions of ICT & Telecom enabling the IT / Telecom as the right arm in business demand and growth.” "
      />

      {/* <InfoSection3
        image={Image1}
        id="about"
        subtitle=""
        TitleInfo="Our Joined Venture with Allied Digital"
        textInfo="Allied Digital is well renowned as a leading Global IT Transformation Architect, with an
              impeccable track record for designing, developing, deploying and delivering end-to-end IT 
              Infrastructure services. Allied Digital responsibly delivers cutting-edge IT services and solutions
              to a wide range of industries spanning 35 countries across 5 continents. As a trusted partner
              with wide range of service capabilities and state-of-the-art global command centers, Allied
              Digital help clients transform and succeed in challenging environments by making better  IT 
              decisions."

        // btnText="Start today"
      /> */}

      {/* <InfoSectionLight
        image={Image5}
        imageA={Image6}
        id="discover"
        subtitle="NTC DIGITAL"
        title="Bussiness Partners"
        // text="Shahnawaz khan || 0560811571"
        // btnText="Explore"
      /> */}

      {/* <HomeInfo
        image={Image4}
        id="discover"
        subtitle="NTC DIGITAL's"
        title="Technology Partners"
        // text="stc || hhh"
        // btnText="Explore"
      /> */}

      <Services />

      <Contact image={Image7} id="Contact" title="Contact US" />
      <Footer />
    </>
  );
};

